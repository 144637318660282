import React, { useContext, useState } from "react"
import MaterialTable from "@material-table/core"
import { Context } from "../state/store"
import styled from "styled-components"

const TextDisplayContent = styled.div`
    white-space: pre-wrap;
`

const EntitySpan = styled.span`
    line-height: 1;
    padding: 0.15em 0;
`

const EntityTypeList = styled.ul`
    li {
        padding: 0.1em 0.25em;
        border-radius: 5px;
        opacity: 0.3;
    }

    li:hover {
        cursor: pointer;
        opacity: 1.0;
    }

    li.showing {
        opacity: 1.0;
    }
`

const EntityStatistics = () => {
    const [state, dispatch] = useContext(Context)
    const { ne_freq_list } = state

    const entity_types = [
        "PERSON","GPE", "LOC", "ORG", "EVENT", "DATE", "PRODUCT", "WORK_OF_ART",
        "CARDINAL", "FAC", "LANGUAGE", "LAW", "MONEY", "NORP", "TIME",
        "ORDINAL", "PERCENT", "QUANTITY"
    ]
    entity_types.sort()
    
    const [showNETypes, updateShowNETypes] = useState([])

    const handleNETypeCLick = (NEType) => {
        if (showNETypes.includes(NEType)) {
            updateShowNETypes(showNETypes.filter(t => t !== NEType))
        } else {
            updateShowNETypes([...showNETypes, NEType])
        }
    }

    const drawEntityText = (tokens) => {
        let spans = []
        let currentSpanItems = [];
        
        for (let i = 0; i < tokens.length; i++) {
            let token = tokens[i]
            if (token.ent_biluo === "B" || token.ent_biluo === "I") {
                currentSpanItems.push(token)
            } else if (token.ent_biluo === "L") {
                currentSpanItems.push(token)

                spans.push(
                    <EntitySpan 
                        key={i} 
                        className={
                            showNETypes.includes(token.ent_type) ? `ent-${token.ent_type}` : ""
                        }
                    >
                        {currentSpanItems.map((token, idx) => {
                            return (
                                <span key={`${i}-${idx}`}>
                                    {token.text}
                                    { (token.ws && idx < currentSpanItems.length - 1) && 
                                      <span>{token.ws}</span>
                                    }
                                </span>
                            )
                        })}
                    </EntitySpan>
                )
                
                if (token.ws) {
                    spans.push(<span key={`${i}-ws`}>{token.ws}</span>)
                }
                
                currentSpanItems = []
            } else if (token.ent_biluo === "U") {
                spans.push(
                    <EntitySpan 
                        key={i}
                        className={
                            showNETypes.includes(token.ent_type) ? `ent-${token.ent_type}` : ""
                        }
                    >
                        {token.text}
                    </EntitySpan>
                )

                if (token.ws) {
                    spans.push(<span key={`${i}-ws`}>{token.ws}</span>)
                }
            } else {
                spans.push(
                    <span key={i}>
                        {token.text} 
                        { token.ws && <span>{token.ws}</span>}
                    </span>
                )
            }
        }

        return <div>{spans.map(span => span)}</div>
    }


    if (!ne_freq_list.length > 0) {
        return <p>No named entities found...</p>
    }

    const tableColumns = [
        { title: "Rank", field: "rank" },
        { title: "Entity", field: "entity" },
        { title: "Type", field: "type" },
        { title: "Count", field: "count" }
    ]

    const tableData = ne_freq_list.map((row, idx) => {
        return {
            rank: idx + 1,
            entity: row[0],
            type: row[1],
            count: row[2]
        }
    })

    return (
        <div>
            <div className="pb-3">
                <MaterialTable
                    columns={tableColumns}
                    data={tableData}
                    title="Top Entities"
                    className="data-table"
                />
            </div>
            <div className="div-shadow pb-3">
                <h5>Entity Explorer</h5>
                <EntityTypeList className="list-inline py-3">
                    {entity_types.map((entity_type, idx) => {
                        return (
                            <li 
                                key={idx}
                                className={`
                                    list-inline-item my-2 ent-${entity_type}
                                    ${showNETypes.includes(entity_type) ? "showing" : ""}
                                `}
                                onClick={() => handleNETypeCLick(entity_type)}
                            >
                                {entity_type}
                            </li>
                        )
                    })}
                </EntityTypeList>
                <TextDisplayContent>
                    {state.tokens && drawEntityText(state.tokens)}
                </TextDisplayContent>
            </div>
        </div>
    )
}

export default EntityStatistics
