import React, { useContext, useState } from "react"
import MaterialTable from "@material-table/core"
import { Context } from "../state/store"
import styled from "styled-components"

const TextDisplayContent = styled.div`
    white-space: pre-wrap;
`

const HighlightTypeList = styled.ul`
    li {
        padding: 0.1em 0.25em;
        border-radius: 5px;
        opacity: 0.3;
    }

    li:hover {
        cursor: pointer;
        opacity: 1.0;
    }

    li.showing {
        opacity: 1.0;
    }
`

const SentenceStatistics = () => {
    const [state, dispatch] = useContext(Context)
    const { features } = state
    
    const highlightTypes = [
        "LONG", "NEGATIVE", "POSITIVE", "SUBJECTIVE", "OBJECTIVE"
    ]

    const [currentHighlight, updateHighlight] = useState("")

    const handleHighlightTypeClick = (highlightType) => {
        if (highlightType === currentHighlight) {
            updateHighlight("")
        } else {
            updateHighlight(highlightType)
        }
    }

    if (!features) {
        return <p>Analyze text to calculate sentence statistics...</p>
    }

    const tableColumns = [
        { title: "Statistic", field: "statistic" },
        { title: "Value", field: "value" }
    ]

    const tableData = [
        { statistic: "Sentence count", value: features.sentence_count },
        { statistic: "Average words per sentence", value: features.avg_sentence_length.toFixed(2) },
        { statistic: "Words in longest sentence", value: features.longest_sent[1] },
        { statistic: "Average sentence polarity", value: features.avg_sentence_polarity.toFixed(2) },
        { statistic: "Average sentence subjectivity", value: features.avg_sentence_subjectivity.toFixed(2) }
    ]

    const getSentenceHighlight = (sentence) => {
        if (currentHighlight === "LONG") {
            return sentence.length > 20 ? "highlight-LONG" : ""
        } else if (currentHighlight === "NEGATIVE") {
            return sentence.polarity < -0.25 ? "highlight-NEGATIVE" : ""
        } else if (currentHighlight === "POSITIVE") {
            return sentence.polarity > 0.25 ? "highlight-POSITIVE" : ""
        } else if (currentHighlight === "SUBJECTIVE") {
            return sentence.subjectivity > 0.75 ? "highlight-SUBJECTIVE" : ""
        } else if (currentHighlight === "OBJECTIVE") {
            return sentence.subjectivity < 0.25 ? "highlight-OBJECTIVE" : ""
        } else {
            return ""
        }
    }

    return (
        <div>
            <div className="pb-3">
                <MaterialTable
                    columns={tableColumns}
                    data={tableData}
                    title="Sentence Statistics"
                    options={{
                        paging: false,
                        search: false
                    }}
                    className="data-table"
                />
            </div>
            <div className="pb-3">
                <div className="div-shadow">
                    <h5>Notable Sentences</h5>
                    <h6>Longest Sentence</h6>
                    <p>
                        { features.longest_sent[0] } 
                        <span className="text-muted"> ({ features.longest_sent[1] } words)</span>
                    </p>
                    <h6>Most Positive Sentence</h6>
                    <p>
                        { features.most_positive_sent[0] } 
                        <span className="text-muted"> ({ features.most_positive_sent[1].toFixed(2) } polarity)</span>
                    </p>
                    <h6>Most Negative Sentence</h6>
                    <p>
                        { features.most_negative_sent[0] } 
                        <span className="text-muted"> ({ features.most_negative_sent[1].toFixed(2) } polarity)</span>
                    </p>
                    <h6>Most Objective Sentence</h6>
                    <p>
                        { features.most_objective_sent[0] } 
                        <span className="text-muted"> ({ features.most_objective_sent[1].toFixed(2) } subjectivity)</span>
                    </p>
                    <h6>Most Subjective Sentence</h6>
                    <p>
                        { features.most_subjective_sent[0] } 
                        <span className="text-muted"> ({ features.most_subjective_sent[1].toFixed(2) } subjectivity)</span>
                    </p>
                </div>
            </div>
            <div className="div-shadow">
                <h5>Sentence Explorer</h5>
                <HighlightTypeList className="list-inline py-3">
                    {highlightTypes.map((highlightType, idx) => {
                        return (
                            <li 
                                key={idx}
                                className={`
                                    list-inline-item my-2 highlight-${highlightType}
                                    ${currentHighlight === highlightType ? "showing" : ""}
                                `}
                                onClick={() => handleHighlightTypeClick(highlightType)}
                            >
                                {highlightType}
                            </li>
                        )
                    })}
                </HighlightTypeList>
                <TextDisplayContent>
                    { state.sentences && state.sentences.map((sentence, idx) => {
                            return (
                                <span key={idx}>
                                    <span 
                                        className={getSentenceHighlight(sentence)}
                                    >
                                        {sentence.text}
                                    </span>
                                    <span> </span>
                                </span>
                                )
                            }
                        )
                    }
                </TextDisplayContent>
            </div>
        </div>
    )
}

export default SentenceStatistics
