import React, { useState, useContext } from "react"
import { MENU_WORDS } from "../constants/menu"
import * as actions from "../constants/actions"
import { Context } from "../state/store"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"

// api link
const api_link = "https://lemmalytica-app-7a2hs7r2tq-uw.a.run.app/api/analyze"  // production
// const api_link = "http://localhost:5000/api/analyze" // development

const TextForm = () => {
    const [state, dispatch] = useContext(Context)
    const [inputText, setInputText] = useState("")
    
    const handleSubmit = (e) => {
        e.preventDefault()
        
        dispatch({type: actions.SET_LOADING_TRUE})

        const request_params = {
            method: "POST", 
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({"text": inputText})
        }

        fetch(api_link, request_params)
            .then(res => res.json())
            .then(json => {
                const { 
                    text, 
                    features,
                    word_freq_list, 
                    ne_freq_list,
                    tokens,
                    sentences
                } = json
                
                dispatch({
                    type: actions.SET_TEXT,
                    payload: text
                })

                dispatch({
                    type: actions.SET_TOKENS,
                    payload: tokens
                })
                
                dispatch({
                    type: actions.SET_SENTENCES,
                    payload: sentences
                })

                dispatch({
                    type: actions.SET_FEATURES,
                    payload: features
                })

                dispatch({
                    type: actions.SET_WORD_FREQ_LIST,
                    payload: word_freq_list
                })

                dispatch({
                    type: actions.SET_NE_FREQ_LIST,
                    payload: ne_freq_list
                })

                dispatch({
                    type: actions.SET_MAIN_DISPLAY,
                    payload: MENU_WORDS
                })
                
                dispatch({type: actions.SET_LOADING_FALSE})
            })
            .catch(error => dispatch({
                type: actions.SET_ERROR, 
                payload: error
            }))
    }

    return (
        <div>
            {state.loading
                ? <div className="my-4">
                    <FontAwesomeIcon icon={ faCog } size="3x" spin />
                  </div>
                : <form onSubmit={ handleSubmit }>
                    <div>
                        <label htmlFor="text" className="form-label">Enter text below and click <strong>analyze</strong>.</label>
                        <textarea
                            onChange={ (e) => setInputText(e.target.value) } 
                            value={inputText} 
                            className="form-control"
                            type="text" 
                            name="text" 
                            id="text"
                            rows="15"
                            required
                        >
                        </textarea>
                    </div>
                    <div className="my-2">
                        <button 
                            className="btn btn-primary"
                            type="submit"
                            disabled={!inputText}
                        >
                            <FontAwesomeIcon icon={faCog} />
                            <span className="mx-2">Analyze</span>
                        </button>
                    </div>
                </form>
            }
        </div>
    )
}

export default TextForm
