import React, { useContext } from "react"
import { Context } from "../state/store"
import { SET_MAIN_DISPLAY, CLEAR_STATE } from "../constants/actions"
import * as menu from "../constants/menu"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
    faUsers, 
    faFont, 
    faParagraph, 
    faBook, 
    faBookReader, 
    faTrash 
} from "@fortawesome/free-solid-svg-icons"

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: #ffffff;
    color: #ffffff;
`

const SidebarMenu = styled.ul`
    display: flex;
    align-items: center;
    flex-direction: column;
    list-style: none;
    width: 100%;
    padding: 20px;
`

const SidebarMenuItem = styled.li`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    padding-left: 10px;
    &:hover {
        background: #044b6710;
        box-shadow: inset 3px 0 0 0 #044b67;
        cursor: pointer;
    }
    &.menu-active {
        background: #642ea61f;
        box-shadow: inset 3px 0 0 0 #044b67;
        cursor: pointer;
    }
    &.menu-danger:hover {
        background: #BA274A1f;
        box-shadow: inset 3px 0 0 0 #BA274A;
    }
`

const SidebarMenuItemLabel = styled.p`
    font-family: "Roboto", sans-serif;
    color: #044b67;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    text-align: left;
    padding: 0;
    margin: 0;
    &.label-danger {
        color: #BA274A;
    }
`

const Sidebar = () => {
    const [state, dispatch] = useContext(Context);

    const handleMenuClick = (val) => {
        dispatch({
            "type": SET_MAIN_DISPLAY,
            "payload": val
        })
    }

    const getActiveMenu = (val) => {
        return state.main_display === val ? "menu-active" : ""
    }

    return (
        <SidebarContainer className="main-sidebar">
            <SidebarMenu>
                { !state.text &&
                    <SidebarMenuItem
                        onClick={() => handleMenuClick(menu.MENU_TEXT)}
                        className={ getActiveMenu(menu.MENU_TEXT) }
                    >
                        <SidebarMenuItemLabel>
                            <FontAwesomeIcon icon={ faBook } />
                            <span className="px-3">Text</span>
                        </SidebarMenuItemLabel>
                    </SidebarMenuItem>
                }
                { state.text && 
                    <SidebarMenuItem
                        onClick={() => handleMenuClick(menu.MENU_WORDS)}
                        className={ getActiveMenu(menu.MENU_WORDS) }
                    >
                        <SidebarMenuItemLabel>
                            <FontAwesomeIcon icon={ faFont } />
                            <span className="px-3">Words</span>
                        </SidebarMenuItemLabel>
                    </SidebarMenuItem>
                }
                { state.text && 
                    <SidebarMenuItem
                        onClick={() => handleMenuClick(menu.MENU_SENTENCES)}
                        className={ getActiveMenu(menu.MENU_SENTENCES) }
                    >
                        <SidebarMenuItemLabel>
                            <FontAwesomeIcon icon={ faParagraph } />
                            <span className="px-3">Sentences</span>
                        </SidebarMenuItemLabel>
                    </SidebarMenuItem>
                }
                { state.text &&
                    <SidebarMenuItem
                        onClick={() => handleMenuClick(menu.MENU_READABILITY)}
                        className={ getActiveMenu(menu.MENU_READABILITY) }
                    >
                        <SidebarMenuItemLabel>
                            <FontAwesomeIcon icon={ faBookReader } />
                            <span className="px-3">Readability</span>
                        </SidebarMenuItemLabel>
                    </SidebarMenuItem>
                }
                { state.text && 
                    <SidebarMenuItem
                        onClick={() => handleMenuClick(menu.MENU_ENTITIES)}
                        className={ getActiveMenu(menu.MENU_ENTITIES) }
                    >
                        <SidebarMenuItemLabel>
                            <FontAwesomeIcon icon={ faUsers } />
                            <span className="px-3">Entities</span>
                        </SidebarMenuItemLabel>
                    </SidebarMenuItem>
                }
                { state.text && 
                    <SidebarMenuItem
                        onClick={() => {
                            dispatch({type: CLEAR_STATE})
                        }}
                        className="menu-danger"
                    >
                        <SidebarMenuItemLabel
                            className="label-danger"
                        >
                            <FontAwesomeIcon icon={ faTrash } />
                            <span className="px-3">Clear</span>
                        </SidebarMenuItemLabel>
                    </SidebarMenuItem> 
                }
            </SidebarMenu>
        </SidebarContainer>
    )
}

export default Sidebar
