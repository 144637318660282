import React, { useContext } from "react"
import MaterialTable from "@material-table/core"
import { Link } from "gatsby"
import { Context } from "../state/store"
import styled from "styled-components"

const TextDisplayContent = styled.div`
    white-space: pre-wrap;
`

const TokenSpan = styled.span`
    line-height: 1;

    &.polysyllable {
        background: #ffd0d0;
        padding: 0.15em 0;
    }
`

const ReadabilityStatistics = () => {
    const [state, dispatch] = useContext(Context)
    const { features } = state
    
    if (!features) {
        return <p>Analyze text to calculate readability statistics...</p>
    }

    const { readability_scores } = features;

    const readabilityLinks = {
        "Number difficult words": "/references/difficult-words",
        "Flesch reading ease": "/references/flesch-reading-ease",
        "Flesch-Kincaid grade": "/references/flesch-kincaid-grade",
        "SMOG index": "/references/smog-index",
        "Gunning-Fog": "/references/gunning-fog",
        "Automated readability index": "/references/automated-readability-index",
        "Dale-Chall score": "/references/dale-chall-score",
    }

    const tableColumns = [
        { title: "Statistic", 
          field: "statistic",
          render: rowData => (
            <Link to={readabilityLinks[rowData.statistic]}>
                {rowData.statistic}
            </Link>
          )
        },
        { title: "Value", field: "value" },
        { title: "Years of education", field: "education" }
    ]

    const convertDaleChall = val => {
        if (val >= 9) {
            return 13
        } else if (val >= 8) {
            return 11
        } else if (val >= 7) {
            return 9
        } else if (val >= 6) {
            return 6
        } else if (val >= 5) {
            return 5
        } else {
            return 4
        }
    }

    const convertFleschReading = val => {
        if (val >= 90) {
            return 5
        } else if (val >= 80) {
            return 6
        } else if (val >= 70) {
            return 7
        } else if (val >= 60) {
            return 8
        } else if (val >= 55) {
            return 9
        } else if (val >= 50) {
            return 11
        } else if (val >= 45) {
            return 13
        } else if (val >= 40) {
            return 15
        } else if (val >= 30) {
            return 16
        } else if (val >= 20) {
            return 18
        } else if (val >= 10) {
            return 20
        } else {
            return 22
        }
    }

    const convertReadabilityValue = (key, val) => {
        switch(key) {
            case "Dale-Chall score":
                return convertDaleChall(val)
            case "Flesch reading ease":
                return convertFleschReading(val)
            default:
                return Math.ceil(val)
        }
    }

    const tableData = Object.entries(readability_scores).map(([key, val]) => {
        return {
            statistic: key,
            value: val,
            education: convertReadabilityValue(key, val)
        }
    })

    const drawToken = (token, idx) => {
        return (
            <span key={idx}>
                <TokenSpan
                    className={
                        token.syllable_length >= 4 ? "polysyllable" : ""
                    }
                >
                    {token.text}
                </TokenSpan>
                { token.ws && <span>{token.ws}</span> }
            </span>
        )
    }

    return (
        <div>
            <div className="pb-3">
                <MaterialTable
                    columns={tableColumns}
                    data={tableData}
                    title="Readability Statistics"
                    options={{
                        paging: false,
                        search: false
                    }}
                    className="data-table"
                />
            </div>
            <div className="div-shadow pb-3">
                <h5>Difficult Words</h5>
                <TextDisplayContent>
                    {state.tokens && state.tokens.map((token, idx) => {
                        return drawToken(token, idx)
                    })}
                </TextDisplayContent>
            </div>
        </div>
    )
}

export default ReadabilityStatistics
