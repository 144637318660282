import React from "react"
import { Router } from "@reach/router"
import LayoutSidebar from "../components/layoutSidebar"
import Analyzer from "../components/analyzer"

const App = () => {
    return (
        <LayoutSidebar pageTitle="App">
            <Router basepath="/app">
                <Analyzer path="/" />
            </Router>
        </LayoutSidebar>
    )
}

export default App
