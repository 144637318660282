import React, { useContext } from "react"
import { Context } from "../state/store"
import * as menu from "../constants/menu"
import TextForm from "./textForm"
import WordStatistics from "./wordStatistics"
import SentenceStatistics from "./sentenceStatistics"
import ReadabilityStatistics from "./readabilityStatistics"
import EntityStatistics from "./entityStatistics"

const Analyzer = () => {
    const [state, dispatch] = useContext(Context)
    
    const getMainDisplay = () => {        
        switch(state.main_display) {
            case menu.MENU_TEXT:
                return <TextForm />
            case menu.MENU_WORDS:
                return <WordStatistics />
            case menu.MENU_SENTENCES:
                return <SentenceStatistics />
            case menu.MENU_READABILITY:
                return <ReadabilityStatistics />
            case menu.MENU_ENTITIES:
                return <EntityStatistics />
            default:
                return <p>You must analyze text first...</p>
        }
    }

    return (
        <div id="dashboard">
            { getMainDisplay() }
        </div>
    );
}

export default Analyzer
