import * as React from "react"
import NavBar from "./navbar"
import Sidebar from "./sidebar"
import Footer from "./footer"
import SEO from "./seo"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const MainContent = styled.main`
    padding: 25px 25px 75px 25px;

    a {
        color: #007991;
    }
      
    a:hover {
        color: #05668D;
    }

    @media (min-width: 800px) {
        padding: 25px 50px 75px 25px;
    }
`

const LayoutSidebar = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div>
            <SEO title={pageTitle} />
            <div className="wrapper-with-sidebar">
                <NavBar />
                <MainContent className="main-content">
                    { children }
                </MainContent>
                <Sidebar />
                <Footer />
            </div>
        </div>
    )
}

export default LayoutSidebar
