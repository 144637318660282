import React, { useContext } from "react"
import MaterialTable from "@material-table/core"
import { Context } from "../state/store"
import { SET_INTEREST_WORD, CLEAR_INTEREST_WORD } from "../constants/actions"
import { Link } from "gatsby"
import styled from "styled-components"

const TextDisplayContent = styled.div`
    white-space: pre-wrap;
`

const TokenSpan = styled.span`
    line-height: 1;

    &:hover {
        background: #b4bbe3;
        padding: 0.15em 0;
        cursor: pointer;
    }

    &.token-selected {
        background: #aad8e1;
        padding: 0.15em 0;
        cursor: pointer;
    }
`

const WordStatistics = () => {
    const [state, dispatch] = useContext(Context)
    const { features, word_freq_list, tokens } = state

    const handleSpanClick = (token) => {
        if (token === state.interest_word) {
            dispatch({"type": CLEAR_INTEREST_WORD})
        } else {
            dispatch({
                "type": SET_INTEREST_WORD,
                "payload": token
            })
        }
    }

    const drawToken = (token, idx) => {
        return (
            <span key={idx}>
                <TokenSpan
                    className={
                        token.text.toLowerCase() === state.interest_word.toLowerCase() 
                        ? "token token-selected" 
                        : "token"
                    }
                    onClick={() => handleSpanClick(token.text)}
                >
                    {token.text}
                </TokenSpan>
                { token.ws && <span>{token.ws}</span> }
            </span>
        )
    }

    if (!features) {
        return <p>Analyze text to calculate word statistics...</p>
    }

    const featureLinks = {
        "Lexical diversity": "/references/lexical-diversity",
        "Number of difficult words": "/references/difficult-words"
    }

    const statsTableColumns = [
        { title: "Statistic", 
          field: "statistic",
          render: rowData => {
                if (Object.keys(featureLinks).includes(rowData.statistic)) {
                    return (
                        <Link to={featureLinks[rowData.statistic]}>
                            {rowData.statistic}
                        </Link>
                    )
                } else {
                    return rowData.statistic
                }
          }
        },
        { title: "Value", field: "value" },
    ]

    const statsTableData = [
        { statistic: "Word count", value: features.word_count },
        { statistic: "Unique word count", value: features.unique_word_count },
        { statistic: "Lexical diversity", value: features.lexical_diversity.toFixed(2) },
        { statistic: "Average characters per word", value: features.avg_word_length.toFixed(2) },
        { statistic: "Average syllables per word", value: features.avg_syllable_length.toFixed(2) },
        { statistic: "Percentage pronouns", value: features.percentage_pronouns.toFixed(2) },
        { statistic: "Number of difficult words", value: features.num_difficult_words },
    ]

    const wordsTableColumns = [
        { title: "Rank", field: "rank" },
        { title: "Word", field: "word" },
        { title: "Count", field: "count" }
    ]

    const wordsTableData = word_freq_list
        .filter(arr => arr[0].match(/\S/))
        .map((arr, idx) => {
            return {
                rank: idx + 1,
                word: arr[0],
                count: arr[1]
            }
        })
    
    const sentimentTableColumns = [
        { title: "Word", field: "word" },
        { title: "Polarity", field: "polarity" },
        { title: "Subjectivity", field: "subjectivity" }
    ]

    const sentimentTableData = [
            ...new Map(
                tokens.map(token => {
                    return {...token, text: token.text.toLowerCase() }
                })            
                .map(token => [token["text"], token])
            ).values()
        ].filter(
            token => token.is_word && token.text.trim() === token.text
        ).sort((a, b) => {
            return a.polarity - b.polarity
        }).map(token => {
            return {
                word: token.text,
                polarity: token.polarity.toFixed(2),
                subjectivity: token.subjectivity.toFixed(2)
            }
        })
    
    const getWordCount = (word) => {
        try {
            return word_freq_list
                .filter(arr => arr[0].toLowerCase() === word.toLowerCase())[0][1]
        } catch (e) {
            return `count not available for stopwords like "${word}"...`
        }
    }

    return (
        <div>
            <div className="pb-3">
                <MaterialTable
                    columns={statsTableColumns}
                    data={statsTableData}
                    title="Word Statistics"
                    options={{
                        paging: false,
                        search: false
                    }}
                    className="data-table"
                />
            </div>
            <div className="pb-3">
                <MaterialTable
                    columns={wordsTableColumns}
                    data={wordsTableData}
                    title="Word Frequency"
                    className="data-table"
                />
            </div>
            <div className="pb-3">
                <MaterialTable
                    columns={sentimentTableColumns}
                    data={sentimentTableData}
                    title="Word Sentiment"
                    className="data-table"
                />
            </div>
            <div className="div-shadow pb-3">
                <h5>Word Explorer</h5>
                <div className="text-muted pb-3">
                    { state.interest_word
                    ? <span>
                          <strong>Current word:</strong> {state.interest_word} 
                          <span className="px-1">({ getWordCount(state.interest_word) })</span>
                      </span>
                    : "Click a word to show its frequency count..."
                    }
                </div>
                <TextDisplayContent>
                    {state.tokens && state.tokens.map((token, idx) => {
                        return drawToken(token, idx)
                    })}
                </TextDisplayContent>
            </div>
        </div>
    )
}

export default WordStatistics
